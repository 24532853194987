import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { Workspace } from '../components/Workspace';

import config from '../../config'

// styles
// TODO

const AddWhitelistForm = () => {
  const [name, setName] = useState("")
  const [tier, setTier] = useState("")
  const [apikey, setApikey] = useState("")
  const [links, setLinks] = useState([])



  const [ whitelistRequests, setWhitelistRequests ] = useState([])
  const getWhitelist = async (name) => {    
    const response = await fetch(`${config.backend_api_url}/whitelist/whitelist`,{
      headers: {
        'x-api-key': apikey
      }
    })
    const response_body = await response.json()
    console.log("WhitelistRequest response_body",response_body)
    setWhitelistRequests(response_body)
  }


  const getWhitelistLink = async (name) => {
    const queryParam = new URLSearchParams({name: name, tier: tier})
    const wl_link = await fetch(
      `${config.backend_api_url}/whitelist/createLink?${queryParam}`,{
        method: 'GET',
        headers: {
          'x-api-key': apikey
        }
    })
    return wl_link.json()
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(`The name you entered was: ${name}`);
    // const newLink = await getWhitelistLink(name);
    const newLink = await getWhitelistLink(name)
    console.log(newLink)
    setLinks(links.concat([newLink]))
  }


  return (
    <>
      <form onSubmit={handleSubmit}>
        <label>Enter name:
          <input 
            type="text" 
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>Tier:
          <input 
            type="text" 
            value={tier}
            onChange={(e) => setTier(e.target.value)}
          />
        </label>
        <label>Enter api key:
          <input 
            type="text" 
            value={apikey}
            onChange={(e) => setApikey(e.target.value)}
          />
        </label>
        <input type="submit" />
        {
          links.map( ({ name, link })=>{
            console.log("link: ",link)
            return <p key={link}>name: {name} link:<a href={link}> {link}</a></p>
          })
        }
      </form>
    
      <h3>white list registered</h3>
      <button onClick={getWhitelist}> get Whitelist Request</button>
      {
        whitelistRequests.map( ({ token, name ,address, tier, timestamp })=> {
          return <p>token: {token}, name: {name}, address: {address}, tier: {tier}, timestamp: {timestamp}</p>
        })
      }
    </>
  )
}



// markup
const WhitelistAdminPage = () => {
  return (
    <>
      <ToastContainer />
      <title>IRON POC</title>
      <h1>IRON whitelist admin Page</h1>
      <h3>white list links</h3>
      <AddWhitelistForm />
    </>
  )
}

export default WhitelistAdminPage
